// src/pages/index.js

import React from 'react'
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './e-books.module.css';
import { Link } from 'gatsby';


const IndexPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">E-Books</h1>
          <p>E-Books on charisma, dating, weight training, and other practical philosophy topics.</p>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4">
          <article>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Link to="/e-books/masculine-magnetism" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/masculine-magnetism-cover.jpg"
                      alt="Masculine Magnetism Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Masculine Magnetism</h2>
                    <p className="text-gray-700 text-base">
                      Charisma is not an inborn trait. It is a skill that can be built, developed, and mastered. 
                      The 30-Day Masculine Magnetism Protocol is designed to rewire your brain, body, and behavior 
                      to make confidence, presence, and influence your natural state.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/attraction" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/attraction-cover.jpg"
                      alt="Attraction Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Attraction</h2>
                    <p className="text-gray-700 text-base">
                      Most men think attraction is about being nice, stable, and always available—but they're wrong. 
                      Women aren't drawn to comfort alone; they crave strength, purpose, and leadership. This short, 
                      no-nonsense guide breaks down the three key steps to keeping a woman emotionally hooked—without 
                      playing games, chasing, or sacrificing your mission.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/red_flags" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/red-flags-cover.jpg"
                      alt="Green Flags vs. Red Flags Cheatsheet Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Green Flags vs. Red Flags Cheatsheet</h2>
                    <p className="text-gray-700 text-base">
                      Most men don't fail in relationships because they missed the signs—they fail because they ignored them. 
                      This no-nonsense guide breaks down the key behaviors, traits, and patterns that separate a godly, feminine, 
                      supportive woman from a problem woman who will drain your peace and destroy your future.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/respect" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/respect-cover.jpg"
                      alt="7 Rules for Commanding Respect Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">7 Rules for Commanding Respect</h2>
                    <p className="text-gray-700 text-base">
                      A woman cannot love a man she does not respect. If you find yourself being ignored, dismissed, or constantly tested, it's because you allowed it.
                      This short, no-nonsense guide breaks down the seven key rules every man must follow to command respect effortlessly—without arguing, chasing, or seeking approval.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/lenten-mass" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/lenten-mass-cover.jpg"
                      alt="Lenten Mass Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Lenten Mass</h2>
                    <p className="text-gray-700 text-base">
                      Fasting doesn't mean weakness. Too many men assume they have to sacrifice strength, muscle, and performance during Lent—but that's not true. 
                      With the right recipes, you can train hard, build muscle, and stay strong while honoring the fast. This Orthodox cookbook shows you how.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/frame" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/frame-cover.jpg"
                      alt="Frame Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Frame</h2>
                    <p className="text-gray-700 text-base">
                      Women don't test men to be difficult—they test men to find out who they truly are. If you've ever had a woman pick a fight out of nowhere, 
                      pull back when things were going well, or push your boundaries, you've been tested. This guide gives you the exact blueprint to pass every test effortlessly.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/e-books/status" 
                className="block p-8 bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow h-full">
                <div className="flex flex-col md:flex-row gap-8 w-[100%]">
                  <div className="w-full md:w-1/3 flex-shrink-0">
                    <StaticImage 
                      src="../images/status-cover.jpg"
                      alt="Status Book Cover"
                      className="w-full rounded-lg"
                      objectFit="contain"
                      aspectRatio={1/1.3}
                    />
                  </div>
                  <div className="overflow-hidden">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Status</h2>
                    <p className="text-gray-700 text-base">
                      Every man wants to be respected, desired, and followed—but most have no idea what actually makes them high-value. 
                      It's not money, status, or luck. It's security. This guide breaks down every core trait that matters and gives you 
                      a step-by-step roadmap to become a man who commands attention everywhere he goes.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
